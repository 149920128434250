import React from "react";
import { connect } from "react-redux";
import { scaleLinear } from "d3-scale";
import { extent, ascending, descending } from "d3-array";
import { line, curveBasis } from "d3-shape";
import Classnames from "classnames";
import "./style.scss";
import styleVars from "../../styling/global_variables.scss";
import { CLOSE, pctFormat } from "../../constants";
import { checkForValue } from "../../helpers";

class SparkLine extends React.Component {
  render() {
    const { data, height, width } = this.props;
    let filteredData = data.filter(d => checkForValue(d[CLOSE]));
    if (filteredData.length <= 1) return false;
    const radius = 2; // TODO: decide where to put this

    const xScale = scaleLinear()
      .domain([0, filteredData.length])
      .rangeRound([0, width]);

    const yScale = scaleLinear()
      .domain(extent(filteredData, d => d[CLOSE]))
      .range([height - radius, radius]) //make sure there is room for the circle
      .nice();

    const path = line()
      .x((d, i) => xScale(i))
      .y(d => yScale(d[CLOSE]))
      .curve(curveBasis);

    return (
      <svg className="sparklineSvg" height={height} width={width}>
        <circle
          cx={xScale(filteredData.length - 1)}
          cy={yScale(filteredData[filteredData.length - 1][CLOSE])}
          r={radius}
        />
        <path d={path(filteredData)} />
      </svg>
    );
  }
}

class Row extends React.Component {
  constructor(props) {
    super(props);
    this.sparkDiv = React.createRef();
  }

  render() {
    const {
      row,
      selected,
      hover,
      onClick,
      onHover,
      toggleCollapse,
      isExpanded,
    } = this.props;
    const width = +styleVars.sparklineWidth.slice(0, -2);
    const imgPath = row.imgUrl
      ? `https://eodhistoricaldata.com${row.imgUrl}`
      : null;

    return (
      <div
        className={Classnames("ticker-row", {
          open: isExpanded,
          highlight: selected ? selected === row.ticker : hover === row.ticker,
        })}
      >
        <div
          className="summary"
          onMouseEnter={() => onHover(row.ticker)}
          onMouseLeave={() => onHover(null)}
          onClick={() => {
            toggleCollapse(row.ticker);
            onClick(row.ticker);
            onHover(null);
          }}
        >
          <div className={"ticker"}>
            {" "}
            <span> {row.ticker} </span>{" "}
          </div>
          <div className={"compName"}>
            <span> {row.name.toLowerCase()} </span>
          </div>
          <div className={"sparkline"} ref={this.sparkDiv}>
            <SparkLine
              data={row.history}
              height={26} // TO DO: this should be stored somewhere or set in style maybe
              width={
                this.sparkDiv.current
                  ? this.sparkDiv.current.getBoundingClientRect().width
                  : width
              }
            />
          </div>
          <div className={"weight"}>
            {" "}
            <span> {pctFormat(row.weight)} </span>{" "}
          </div>
        </div>
        <div className="expandable">
          {imgPath && (
            <div className="logo">
              <img alt="logo" src={imgPath} />
            </div>
          )}
          {row.description ? (
            <div className="description">{row.description}</div>
          ) : null}
        </div>
      </div>
    );
  }
}

class HoldingsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedComponent: null,
      isSortAsc: true,
      sortKey: "ticker",
    };
    this.setSort = this.setSort.bind(this);
  }

  toggleCollapse(ticker) {
    this.setState({
      expandedComponent:
        this.state.expandedComponent === ticker ? null : ticker,
    });
  }

  setSort(sortKey) {
    this.setState({
      ...(sortKey !== this.state.sortKey
        ? { isSortAsc: true }
        : { isSortAsc: !this.state.isSortAsc }), // only change sort order if same column is clicked
      sortKey,
    });
  }

  render() {
    const { data } = this.props;
    const sortedIndices = data
      .map((d, i) => ({ index: i, val: d[this.state.sortKey] }))
      .sort((a, b) =>
        [descending, ascending][+this.state.isSortAsc](a.val, b.val)
      );

    return (
      <div className="HoldingsTable">
        <div className="header">
          {[
            ["ticker", "Ticker", true],
            ["name", "Name", true],
            ["perf", "Performance", false],
            ["weight", "Weight", true],
          ].map(c => (
            <div
              className={c[0]}
              onClick={() => c[2] && this.setSort(c[0])}
              key={c[0]}
            >
              {c[1]}
              {c[2] && <span className="arrow" />}
            </div>
          ))}
        </div>
        <div className="body">
          {sortedIndices.map(d => {
            const row = data[d.index];
            return (
              <Row
                key={row.ticker}
                isExpanded={row.ticker === this.state.expandedComponent}
                row={row}
                hover={this.props.hover}
                selected={this.props.selected}
                onClick={this.props.onClick}
                toggleCollapse={this.toggleCollapse.bind(this)}
                onHover={this.props.onHover}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  hover: state.interaction.hover,
  selected: state.interaction.selected,
});

export default connect(mapStateToProps)(HoldingsTable);
